import React, { useCallback, useEffect, useState } from 'react';
import {
  MaterialReactTable,
  MRT_TableOptions,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ToggleFullScreenButton,
  MRT_Row,
  MRT_ToggleFiltersButton,
  MRT_Updater,
  MRT_RowData,
} from 'material-react-table';
import { Box, InputAdornment, SxProps } from '@mui/material';
import { Theme } from '@mui/system';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { MRT_Localization_DE } from 'material-react-table/locales/de';
import useTranslation from 'hooks/useTranslation';
import IconButton from 'components/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import MRT_ShowHideColumnsButton from './components/buttons/MRT_ShowHideColumnsButton';
import { MRT_BottomToolbar } from './components/toolbar/MRT_BottomToolbar';
import {
  CollapseDiagonalIcon,
  ExpandDiagonalIcon,
  FilterIcon,
  FilterOffIcon,
  SearchIcon,
  TableIcon,
} from 'components/Icons';

export interface MRTProps<TData extends MRT_RowData>
  extends MRT_TableOptions<TData> {
  onRefresh?: () => void;
  getIsRowDisabled?: (row: MRT_Row<TData>) => boolean;
}

const combineMRTableSxProps = ({
  theme,
  sx,
  outerSx,
}: {
  theme: Theme;
  sx: SxProps<Theme>;
  outerSx?: any;
}) => {
  return {
    ...(sx instanceof Function ? sx(theme) : sx),
    ...(outerSx instanceof Function ? outerSx(theme) : outerSx),
  };
};

export default function <TData extends MRT_RowData>({
  initialState,
  state,
  onColumnOrderChange,
  onColumnPinningChange,
  displayColumnDefOptions,
  muiTableBodyProps,
  muiSearchTextFieldProps,
  muiTablePaperProps,
  muiTableContainerProps,
  muiTopToolbarProps,
  muiPaginationProps,
  muiTableBodyRowProps,
  muiTableBodyCellProps,
  muiTableHeadProps,
  mrtTheme,
  renderBottomToolbarCustomActions,
  onRefresh,
  getIsRowDisabled,
  enableColumnPinning = true,
  enableStickyHeader = true,
  enableColumnResizing = true,
  enableColumnOrdering = true,
  enableRowVirtualization = true,
  enableColumnFilters = false,
  enableFullScreenToggle = true,
  enableColumnDragging = false,
  columns: originalColumns,
  icons,
  localization,
  ...rest
}: MRTProps<TData>) {
  const columns = originalColumns.map(column => ({
    ...column,
    ...(column.accessorFn && {
      accessorFn: (originalRow: TData) => column.accessorFn!(originalRow) ?? '',
    }),
  }));
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [isFullScreen, setIsFullScreen] = useState<boolean>(
    state?.isFullScreen ?? false,
  );
  useEffect(() => {
    setIsFullScreen(state?.isFullScreen ?? false);
  }, [state?.isFullScreen]);

  const moveRowActionsToTheEnd = useCallback(
    (columns?: string[]) => {
      if (!rest.enableRowActions) return columns ?? [];
      return (columns ?? [])
        .filter(el => el !== 'mrt-row-actions')
        .concat(['mrt-row-actions']);
    },
    [rest.enableRowActions],
  );

  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    moveRowActionsToTheEnd(
      state?.columnOrder ?? columns.map(c => c.id! ?? c.accessorKey!),
    ),
  );
  useEffect(() => {
    state?.columnOrder &&
      setColumnOrder(moveRowActionsToTheEnd(state.columnOrder));
  }, [state?.columnOrder, moveRowActionsToTheEnd]);
  const columnOrderChangeHandler = React.useCallback(
    (updater: MRT_Updater<MRT_ColumnOrderState>) => {
      setColumnOrder(prev => {
        const newState = moveRowActionsToTheEnd(
          updater instanceof Function ? updater(prev) : updater,
        );
        onColumnOrderChange?.(newState);
        return newState;
      });
    },
    [onColumnOrderChange, moveRowActionsToTheEnd],
  );

  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>({
    ...state?.columnPinning,
    right: moveRowActionsToTheEnd(state?.columnPinning?.right),
  });
  useEffect(() => {
    state?.columnPinning &&
      setColumnPinning({
        ...state?.columnPinning,
        right: moveRowActionsToTheEnd(state?.columnPinning?.right),
      });
  }, [state?.columnPinning, moveRowActionsToTheEnd]);
  const columnPinningChangeHandler = React.useCallback(
    (updater: MRT_Updater<MRT_ColumnPinningState>) => {
      setColumnPinning(prev => {
        const newState = updater instanceof Function ? updater(prev) : updater;
        newState.right = moveRowActionsToTheEnd(newState.right);
        onColumnPinningChange?.(newState);
        return newState;
      });
    },
    [onColumnPinningChange, moveRowActionsToTheEnd],
  );

  return (
    <MaterialReactTable<TData>
      renderToolbarInternalActions={({ table }) => (
        <>
          {enableColumnFilters && <MRT_ToggleFiltersButton table={table} />}
          <MRT_ShowHideColumnsButton table={table} />
          {enableFullScreenToggle && (
            <MRT_ToggleFullScreenButton table={table} />
          )}
        </>
      )}
      {...rest}
      columns={columns}
      initialState={{
        showGlobalFilter: true,
        ...initialState,
        density: 'compact',
      }}
      state={{
        ...state,
        isLoading: !rest.data.length && state?.isLoading,
        showProgressBars: state?.isLoading,
        columnOrder,
        columnPinning,
        isFullScreen,
        density: 'compact',
      }}
      onColumnOrderChange={columnOrderChangeHandler}
      onColumnPinningChange={columnPinningChangeHandler}
      onIsFullScreenChange={setIsFullScreen}
      {...(language === 'de' && {
        localization: MRT_Localization_DE,
      })}
      positionActionsColumn="last"
      enableColumnPinning={enableColumnPinning}
      enableStickyHeader={enableStickyHeader}
      enableColumnResizing={enableColumnResizing}
      enableColumnOrdering={enableColumnOrdering}
      enableRowVirtualization={enableRowVirtualization}
      enableColumnFilters={enableColumnFilters}
      enableDensityToggle={false}
      enableFullScreenToggle={enableFullScreenToggle}
      enableColumnDragging={enableColumnDragging}
      renderBottomToolbarCustomActions={table => (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {renderBottomToolbarCustomActions?.(table)}
          {onRefresh && (
            <IconButton
              onClick={onRefresh}
              tooltip={t('Refresh')}
              sx={{ ml: 'auto' }}
            >
              <RefreshIcon />
            </IconButton>
          )}
        </Box>
      )}
      renderBottomToolbar={({ table }) => (
        <MRT_BottomToolbar table={table} sx={{ boxShadow: 'none' }} />
      )}
      displayColumnDefOptions={{
        ...displayColumnDefOptions,
        'mrt-row-expand': {
          enablePinning: false,
          ...displayColumnDefOptions?.['mrt-row-expand'],
          muiTableHeadCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-expand']
                ?.muiTableHeadCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
          muiTableBodyCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-expand']
                ?.muiTableBodyCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
        },
        'mrt-row-actions': {
          Header: (
            <SettingsOutlinedIcon
              sx={theme => ({
                verticalAlign: '-webkit-baseline-middle',
                marginTop: '-5px',
              })}
            />
          ),
          enablePinning: false,
          ...displayColumnDefOptions?.['mrt-row-actions'],
          muiTableHeadCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-actions']
                ?.muiTableHeadCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              align: 'center',
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                    justifyContent: 'center',
                    py: 0.5,
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
          muiTableBodyCellProps: params => {
            const componentOuterProps =
              displayColumnDefOptions?.['mrt-row-actions']
                ?.muiTableBodyCellProps;
            const transformedComponentOuterProps =
              componentOuterProps instanceof Function
                ? componentOuterProps(params)
                : componentOuterProps;

            return {
              ...transformedComponentOuterProps,
              sx: theme =>
                combineMRTableSxProps({
                  theme,
                  sx: {
                    maxWidth: 'fit-content',
                    justifyContent: 'center',
                  },
                  outerSx: transformedComponentOuterProps?.sx,
                }),
            };
          },
        },
      }}
      muiTableBodyProps={params => {
        const transformedComponentOuterProps =
          muiTableBodyProps instanceof Function
            ? muiTableBodyProps(params)
            : muiTableBodyProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                '& tr > td': {
                  borderBottom: 1,
                  borderColor: theme.palette.divider,
                },
                '& td[data-pinned="true"]:before': {
                  backgroundColor: `${theme.palette.surface.primary}`,
                },
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiSearchTextFieldProps={params => {
        const transformedComponentOuterProps =
          muiSearchTextFieldProps instanceof Function
            ? muiSearchTextFieldProps(params)
            : muiSearchTextFieldProps;

        return {
          ...transformedComponentOuterProps,
          variant: 'outlined',
          size: 'small',
          InputProps: {
            ...transformedComponentOuterProps?.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  sx={theme => ({
                    color: theme.palette.text.secondary,
                  })}
                />
              </InputAdornment>
            ),
            sx: theme =>
              combineMRTableSxProps({
                theme,
                sx: {
                  width: 320,
                  minWidth: 320,
                  '& .MuiInputAdornment-positionEnd': {
                    visibility: params.table.getState().globalFilter
                      ? 'visible'
                      : 'hidden',
                    '& .MuiIconButton-root': {
                      //display: 'none',
                      '& .MuiSvgIcon-root': {
                        fontSize: '1rem',
                      },
                      '&:not(.Mui-disabled)': {
                        //display: 'inline',
                        color: theme.palette.primary.main,
                      },
                    },
                  },
                },
                outerSx: transformedComponentOuterProps?.InputProps?.sx,
              }),
          },
        };
      }}
      muiPaginationProps={params => {
        const transformedComponentOuterProps =
          muiPaginationProps instanceof Function
            ? muiPaginationProps(params)
            : muiPaginationProps;

        return {
          ...transformedComponentOuterProps,
          rowsPerPageOptions: [5, 10, 50, 100, 200],
        };
      }}
      muiTablePaperProps={params => {
        const transformedComponentOuterProps =
          muiTablePaperProps instanceof Function
            ? muiTablePaperProps(params)
            : muiTablePaperProps;

        return {
          ...transformedComponentOuterProps,
          elevation: 0,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                ...(isFullScreen && {
                  padding: '8px!important',
                  zIndex: '1201!important',
                }),
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableContainerProps={params => {
        const transformedComponentOuterProps =
          muiTableContainerProps instanceof Function
            ? muiTableContainerProps(params)
            : muiTableContainerProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                border: 1,
                borderRadius: '12px',
                borderColor: theme.palette.divider,
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTopToolbarProps={params => {
        const transformedComponentOuterProps =
          muiTopToolbarProps instanceof Function
            ? muiTopToolbarProps(params)
            : muiTopToolbarProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                '& .MuiBox-root': {
                  px: 0,
                  pt: 0,
                },
                '& .MuiCollapse-hidden': {
                  display: 'none',
                },
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableBodyRowProps={params => {
        const transformedComponentOuterProps =
          muiTableBodyRowProps instanceof Function
            ? muiTableBodyRowProps(params)
            : muiTableBodyRowProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                ...(!enableRowVirtualization && {
                  position: 'relative',
                }),
                ...(getIsRowDisabled?.(params.row) && {
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    zIndex: 2,
                    background: `${theme.palette.surface.disabled}90`,
                  },
                }),
                ...(params.row.getIsSelected() && {
                  '&:hover td': {
                    backgroundColor: '#d0c9ff',
                  },
                }),
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableBodyCellProps={params => {
        const transformedComponentOuterProps =
          muiTableBodyCellProps instanceof Function
            ? muiTableBodyCellProps(params)
            : muiTableBodyCellProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                fontSize: '14px',
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      muiTableHeadProps={params => {
        const transformedComponentOuterProps =
          muiTableHeadProps instanceof Function
            ? muiTableHeadProps(params)
            : muiTableHeadProps;

        return {
          ...transformedComponentOuterProps,
          sx: theme =>
            combineMRTableSxProps({
              theme,
              sx: {
                '& th[data-pinned="true"]:before': {
                  backgroundColor: `${theme.palette.surface.secondary}`,
                },
              },
              outerSx: transformedComponentOuterProps?.sx,
            }),
        };
      }}
      mrtTheme={params => {
        const transformedComponentOuterProps =
          mrtTheme instanceof Function ? mrtTheme(params) : mrtTheme;

        return {
          ...(!isFullScreen && { baseBackgroundColor: '#ffffff00' }),
          ...transformedComponentOuterProps,
        };
      }}
      icons={{
        FilterListIcon: FilterIcon,
        FilterListOffIcon: FilterOffIcon,
        FullscreenIcon: ExpandDiagonalIcon,
        FullscreenExitIcon: CollapseDiagonalIcon,
        ViewColumnIcon: TableIcon,
        ...icons,
      }}
      localization={{
        toggleFullScreen: isFullScreen
          ? t('Toggle off full screen')
          : t('Toggle full screen'),
        ...localization,
      }}
    />
  );
}
